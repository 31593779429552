import React from "react";
import { Button } from "@mui/material";

const JoinWaitingList = () => {
  return (
    <>
      <Button
        variant="contained"
        size="large"
        // color="#005F73"
        style={{
          borderRadius: 35,
          backgroundColor: "#005F73",
          padding: "18px 36px",
          fontSize: "18px",
        }}
        href="https://forms.gle/3z34etnpBRzSVcbA8"
      >
        Join the wait list!
      </Button>
    </>
  );
};

export default JoinWaitingList;
