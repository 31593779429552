import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

export const MapView = ({ location }) => {
  const containerStyle = {
    width: "100%",
    height: "180px",
    borderRadius: "15px",
  };

  const handleMarkerClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={location} zoom={15}>
      <MarkerF position={location} onClick={handleMarkerClick} />
    </GoogleMap>
  );
};
