import "../../App.css";
import { Container } from "@mui/material";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import VendorMobileView from "./VendorMobileView";
import { useMediaQuery } from "react-responsive";
import VendorDesktopView from "./VendorDesktopView";

const theme = createTheme({
  typography: {
    h3: {
      fontFamily: "Poppins",
    },
    h5: {
      fontFamily: "Poppins",
    },
    fontFamily: "OpenSans, Arial, sans-serif",
  },
});

function VendorView() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  console.log("isDesktopOrLaptop", isDesktopOrLaptop);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Container maxWidth="xl">
          {isDesktopOrLaptop ? <VendorDesktopView /> : <VendorMobileView />}
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default VendorView;
