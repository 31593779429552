import React from "react";
import { Box, Typography, Link } from "@mui/material";
import "../../App.css"; // Assuming your CSS file is named App.css

function Footer() {
  return (
    <Box
      className="footer"
      sx={{
        textAlign: "center",
        padding: "10px 0",
        backgroundColor: "#f1f1f1",
        marginTop: "auto",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        By viewing or using this page, you agree to our{" "}
        <Link
          href="_/terms-of-service"
          underline="hover"
          // onClick={handleTOSLinkClick}
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          href="_/privacy-policy"
          underline="hover"
          // onClick={handlePPLinkClick}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Box>
  );
}

export default Footer;
