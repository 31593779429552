import logo from "../../assets/logo_no_text.png";
import appleLogo from "../../assets/appStore.png";
import googleLogo from "../../assets/googlePlay.png";
import "../../App.css";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { theme as sidewalkTheme } from "../../theme";
import MotionPhotosOffIcon from "@mui/icons-material/MotionPhotosOff";
import { MapView } from "../components/MapComponent";
import Footer from "../components/FooterComponent";
import { useNavigate } from "react-router-dom";

const VendorMobileView = () => {
  console.log("VendorMobileView");
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Menu");

  const { slug = "adeats" } = useParams(); // Extracts slug from the URL
  const [storeData, setStoreData] = useState(null);
  const [location, setLocation] = useState({
    lat: 40.7393313,
    lng: -74.0520984,
  });
  const [storeLocationOpen, setStoreLocationOpen] = useState(false);
  const [locationTime, setLocationTime] = useState(null);
  const [error, setError] = useState(null);

  function getDeviceType() {
    let deviceType = ""
    if (/Android/i.test(navigator.userAgent)) {
      deviceType = "android"
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceType = "ios"
    } else {
      deviceType = "desktop"
    }
    console.log(`DeviceType is ${deviceType}`)
    return deviceType
  }

  function openApp() {
    const androidAppLink = `intent://sidewa.lk/${slug}#Intent;scheme=https;package=com.sidewalk.sidewalk;end`;
    const iosAppLink = `sidewalk://${slug}`;

    if (getDeviceType() === "android") {
      window.location.href = androidAppLink;
    } else if (getDeviceType() === "ios") {
      window.location.href = iosAppLink;
    } else {
      // Fallback or prompt for download
      alert("Download the app to open this link.");
    }
  }

  useEffect(() => {
    const fetchVendorData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${slug}/`
      );
      if (response.status !== 200) {
        setError(response.statusText);
        console.log("storefront does not exist");
        navigate("/");
      }

      const response_json = await response.json();
      console.log("fetchVendorData response:", response_json);

      setStoreData(response_json);
    };
    function isVendorOpen(locationTimeString) {
      const currentTime = new Date();
      const thirtyMinutesAgo = new Date(currentTime);
      thirtyMinutesAgo.setMinutes(currentTime.getMinutes() - 1);

      const inputTime = new Date(locationTimeString);

      if (inputTime >= thirtyMinutesAgo && inputTime <= currentTime) {
        return true;
      }
      return false;
    }
    const fetchVendorLocation = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/storefronts/${slug}/location/`
      );

      if (response.status !== 200) {
        // setError(response.statusText);
        return;
      }

      const response_json = await response.json();
      console.log("fetchVendorLocation response:", response_json);

      const vendor_location = {
        lat: response_json.location_coordinate.coordinates[1],
        lng: response_json.location_coordinate.coordinates[0],
      };
      setLocation(vendor_location);
      setLocationTime(response_json.location_time);
      setStoreLocationOpen(isVendorOpen(response_json.location_time));
    };
    fetchVendorData();
    fetchVendorLocation();
  }, [slug]);

  const handleClick = (tabName) => {
    setSelectedTab(tabName);
  };
  return (
    <>
      <br />
      <div className="avatar-container">
        <Avatar
          alt="Logo"
          src={storeData?.logo || logo}
          sx={{
            width: 100,
            height: 100,
            border: "1px solid gray",
            padding: storeData?.logo ? 0 : 2,
          }}
        />
      </div>
      <Typography component="div" gutterBottom>
        <Box className="store-name-box">{storeData?.name}</Box>
      </Typography>
      <Typography
        component="div"
        gutterBottom
        className="store-details-container"
      >
        <Box>{storeData?.cuisine}</Box>
        <Box>{"|"}</Box>
        <Box>
          {storeData?.rating ? (
            <div className="rating-container">
              <Typography component="div">{storeData?.rating}</Typography>
              <StarIcon
                fontSize="small"
                sx={{ color: sidewalkTheme.colors.yellow }}
              />
            </div>
          ) : (
            <Typography component="div">No Rating Yet</Typography>
          )}
        </Box>
      </Typography>

      <Typography variant="body1" component="div">
        <Box>{storeData?.description}</Box>
      </Typography>
      <Grid
        container
        alignItems="center"
        sx={{
          marginTop: 2,
          paddingLeft: 1,
          paddingRight: 1,
          display: "flex",
        }}
      >
        <Grid
          item
          xs="auto"
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {storeLocationOpen ? (
            <div className="pulse-container">
              <div className="pulse-circle"></div>
              <div className="solid-circle"></div>
            </div>
          ) : (
            <MotionPhotosOffIcon sx={{ color: sidewalkTheme.colors.red }} />
          )}
        </Grid>

        <Grid item xs sx={{ textAlign: "left", ml: 1 }}>
          <Typography
            variant="body1"
            component="div"
            className="text-container"
          >
            <Box sx={{ fontWeight: "bold", fontSize: 15, marginLeft: 1 }}>
              {`${storeData?.name} is currently ${storeLocationOpen ? "open" : "closed"
                }`}
            </Box>
          </Typography>
        </Grid>
      </Grid>

      <br />
      <MapView location={location} />
      <br />

      <ButtonGroup variant="text" aria-label="outlined primary button group">
        <Button
          onClick={() => handleClick("Photos")}
          style={{
            backgroundColor: selectedTab === "Photos" ? "teal" : "transparent",
            color: selectedTab === "Photos" ? "white" : "black",
          }}
          className="button-common"
        >
          Photos
        </Button>
        <Button
          onClick={() => handleClick("Menu")}
          style={{
            backgroundColor: selectedTab === "Menu" ? "teal" : "transparent",
            color: selectedTab === "Menu" ? "white" : "black",
            marginRight: 10,
            marginLeft: 10,
          }}
          className="button-common"
        >
          Menu
        </Button>
        <Button
          onClick={() => handleClick("Reviews")}
          style={{
            backgroundColor: selectedTab === "Reviews" ? "teal" : "transparent",
            color: selectedTab === "Reviews" ? "white" : "black",
          }}
          className="button-common"
        >
          Reviews
        </Button>
      </ButtonGroup>

      <div className="download-container">
        <div style={{}}>
          <Typography>
            View menu, photos, reviews and other similar vendors on the Sidewalk
            App!
          </Typography>
        </div>
        <div className="app-download-container">
          {getDeviceType() === "android" || getDeviceType() === "ios" ?
            <Button
              variant="contained"
              size="large"
              // color="#005F73"
              style={{
                borderRadius: 35,
                backgroundColor: "#005F73",
                padding: "18px 36px",
                fontSize: "18px",
              }}
              onClick={openApp}
            >
              Get Sidewalk app
            </Button>
            :
            <>
              <img alt="Logo" src={appleLogo} className="app-logo" />
              <img alt="Logo" src={googleLogo} className="app-logo" />
            </>
          }

        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default VendorMobileView;
